import {FETCH_LATEST_BID_EVENTS} from "./types";

const initialState = {
    latest_bid_events: [],
}

export const latestBidEventsReducer=(state = initialState, action)=> {
    switch (action.type) {
        case FETCH_LATEST_BID_EVENTS:
            return {...state, latest_bid_events: action.payload}
        default:
            return state;
    }
}