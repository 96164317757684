import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchEvents, fetchLatestBidEvents} from "../redux/actions";
import LatestBidOrder from "./data-components/LatestBidOrder";

const LatestBid = () => {
    const dispatch = useDispatch();

    const userToken = useSelector(state=> state.auth.userHash);
    const { id } = useParams();

    useEffect(() => {
        dispatch(fetchLatestBidEvents(userToken, id))
        dispatch(fetchEvents(userToken))
    }, [dispatch, userToken]);

    const event = useSelector(state=> state.events.events.filter(item=>item.id===id))[0]

    const latestBidEvents = useSelector(state=>state.latest_bid_events.latest_bid_events)

    return (
        <div className='container'>
            <nav className="nav nav-pills nav-justified">
                <Link className="nav-link active" to={'/'}>Вернуться к заявкам</Link>
            </nav>
            <div className="d-flex flex-column">
                <div className="p-1 mt-1"><h5>Заявки по клиенту {event?.company.name}</h5></div>
            </div>
            {latestBidEvents.length ? <LatestBidOrder parentId={event?.id} events={latestBidEvents}/> : <p className="font-weight-light">Нет заявок по данному клиенту</p>}
        </div>
    );
};

export default LatestBid;