import {combineReducers} from "redux";
import {eventsReducer} from "./eventsReducer";
import {authReducer} from "./authReducer";
import {notificationReducer} from "./notificationReducer";
import {loaderReducer} from "./loaderReducer";
import {userReducer} from "./userReducer";
import {departmentReducer} from "./departmentReducer";
import {equipmentReducer} from "./equipmentReducer";
import {workCompletedReducer} from "./workCompletedReducer";
import {categoriesReducer} from "./categoriesReducer";
import {undeliveredEquipmentReducer} from "./undeliveredEquipmentReducer";
import {temporaryEquipmentReducer} from "./temporaryEquipmentReducer";
import {latestBidEventsReducer} from "./latestBidEventsReducer";

export const rootReducer=combineReducers({
    events:eventsReducer,
    latest_bid_events:latestBidEventsReducer,
    auth:authReducer,
    notification:notificationReducer,
    loader: loaderReducer,
    user: userReducer,
    department: departmentReducer,
    equipment: equipmentReducer,
    work_completed: workCompletedReducer,
    equip_categories: categoriesReducer,
    undelivered_equipment: undeliveredEquipmentReducer,
    temporary_equipment: temporaryEquipmentReducer
})
