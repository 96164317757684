import React, {useState} from 'react';
import {connect} from "react-redux";
import {authFetch, hideAlert, showAlert} from "../redux/actions";
import {useHistory} from "react-router-dom";
import queryString from 'query-string';

const Login=(props)=> {
    let history = useHistory();

    let params = queryString.parse(props.location.search)

    if (localStorage.getItem('USER_HASH')) {
        window.location.href = '/';
    }

    if (params.hash) {
        localStorage.setItem('USER_HASH', params.hash)
        window.location.href = '/';
    }

    const [inputs, setInputs] = useState({
        login: '',
        password: ''
    });

    const { login, password } = inputs;
    const handleSubmit= (e)=> {
        e.preventDefault();
        if (login.trim() ==='' || password.trim()==='') {
            props.showAlert('Введите данные!')
            setTimeout(()=> {
                props.hideAlert(null)
            },3000)
        }else {
             props.authFetch(login, password, history)
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    return (
        <div className="d-flex height align-items-center">
            <form className="form-signin" onSubmit={handleSubmit}>
                <h1 className="h3 mb-3 font-weight-normal">Авторизация</h1>
                <input type="text" name="login" value={login} onChange={handleChange} id="login" className="form-control mb-3"/>
                <input type="password" name="password" value={password} onChange={handleChange} id="password" className="form-control mb-3" />
                <button className="btn btn-lg btn-support btn-block" type="submit">Войти</button>
            </form>
        </div>
    )
}

const mapDispatchToProps = {
    showAlert,
    authFetch,
    hideAlert
}


export default connect(null, mapDispatchToProps)(Login);
