import React, {useEffect, useState} from 'react';
import Order from "./data-components/Order";
import {connect} from 'react-redux';
import {fetchTemporaryEquipment, fetchUndeliveredEquipment, fetchUser} from "../redux/actions";
import { useDispatch } from 'react-redux';
import EquipmentForm from "./data-components/EquipmentForm";
import {Link} from "react-router-dom";
import ModalHome from "./common/not-reuse/ModalHome";
import FooterTab from "./common/FooterTab";

const Home = (props)=> {

    const dispatch = useDispatch();
    const [active, setActive] = useState({
        app: true,
        equip: false
    });
    useEffect(() => {
        dispatch(fetchUser(props.userHash))
        dispatch(fetchUndeliveredEquipment(props.userHash))
        dispatch(fetchTemporaryEquipment(props.userHash))
    }, [dispatch, props.userHash]);
    const toggleClass =(e)=> {
        e.preventDefault()
        switch (e.target.id) {
            case 'equip':
                setActive({
                    app: false,
                    equip: true
                })
                break;
            case 'app' :
                setActive({
                    app: true,
                    equip: false
                })
                break;
            default: return false;
        }

    }

        return (

            <div className='container'>
                <nav className="nav nav-pills nav-justified">
                    <a id="app" onClick={toggleClass} className={active.app ? "nav-link active":"nav-link"} href="!#">Мои заявки</a>
                    <a id="equip" onClick={toggleClass} className={active.equip ? "nav-link active":"nav-link"} href="!#">ID оборудования</a>
                </nav>
                {active.app &&
                    <>
                        <div className="d-flex flex-column align-items-center">
                            <div className="p-1"><h4>{props.name}, здравствуйте!</h4></div>

                            <ModalHome work={props.work}/>

                            <div className="p-1"><p>Ваша норма на сегодняшний день</p></div>
                            <div className="p-1"><p>{props.rate}</p></div>
                        </div>
                        {props.undelivered_equipment_exists !== null &&
                            <Link className="btn btn-danger" to={'/undelivered-equipment'}>У вас есть недоставленное оборудование</Link>
                        }
                        {props.temporary_equipment !== null &&
                            <Link className={props.undelivered_equipment_exists !== null ? "btn btn-warning mt-2" : "btn btn-warning"} to={'/temporary-equipment'}>На вас числится временное оборудвание</Link>
                        }
                        <div className="d-flex justify-content-between">
                            <div className="p-2 font-weight-bold">Ваши заявки</div>
                            <div className="p-2 font-weight-bold">В работе</div>
                        </div>
                        <Order />
                    </>
                }
                {active.equip &&
                    <EquipmentForm />
                }
                <FooterTab userHash={props.userHash}/>
            </div>
            )
}

const mapStateToProps = state => {
    return {
        name: state.user.name,
        rate: state.user.rate,
        work: {
            imWorking: state.user.work.imWorking,
            readyOrder: state.user.work.readyOrder
        },
        userHash: state.auth.userHash,
        undelivered_equipment_exists: state.undelivered_equipment.undelivered_equipment,
        temporary_equipment: state.temporary_equipment.temporary_equipment
    }
}

export default connect(mapStateToProps, null)(Home);
