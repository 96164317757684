export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const AUTHORIZATION = 'AUTHORIZATION';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_LATEST_BID_EVENTS = 'FETCH_LATEST_BID_EVENTS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_DEPARTMENT_REPAIR = 'FETCH_DEPARTMENT_REPAIR';
export const FETCH_DEPARTMENT_EVENT = 'FETCH_DEPARTMENT_EVENT';
export const FETCH_EQUIPMENT = 'FETCH_EQUIPMENT';
export const FETCH_WORK_COMPLETED = 'FETCH_WORK_COMPLETED';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_UNDELIVERED_EQUIPMENT = 'FETCH_UNDELIVERED_EQUIPMENT';
export const FETCH_TEMPORARY_EQUIPMENT = 'FETCH_TEMPORARY_EQUIPMENT';
