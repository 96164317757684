import React from 'react';
import {Link} from "react-router-dom";
import Timer from "../common/Timer";

const LatestBidOrder = (props) => {
    return (
        <div className="list-group text-left">
            {props.events.map((item,idx)=> (
                <Link to={`/recent-bid-detail/${props.parentId}/${item.id}`} className='order-link' key={idx}>
                    <div className={item.status !=='В работе' ? "d-flex justify-content-between align-items-center list-group-item list-group-item-action item-order color" : "d-flex justify-content-between align-items-center list-group-item list-group-item-action item-order"}>
                        <div>
                            <h5>{item.company.name.trim()}</h5>
                            <p className="font-weight-normal text-dark">{item.topic.trim() === '' ? 'Нет описания':item.topic.trim().replace('=','').slice(0,50)+'...' }</p>
                        </div>
                        <Timer status={item.status} startTime={item.startTime}/>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default LatestBidOrder;